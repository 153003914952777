import React from 'react';
import MainLayout from "@/layout/main/MainLayout";
import {useRequest} from "ahooks";
import {ratePlanRecords} from "@/api/query";
import {useNavigate, useSearchParams} from "react-router-dom";
import {Cell, List, Card, Empty} from 'react-vant'
import {volumUnitFormat} from "@/utils/util";
import TextMore from "@/components/TextMore";

const Query: React.FC = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const iccid = searchParams.get('iccid')
    const otaCard = searchParams.get('otaCard')
    const {data} = useRequest(() => ratePlanRecords({iccid, otaCard }), {
        manual: false,
        refreshDeps: [],
    });
    return (
        <MainLayout
            title="Renew Records"
            navBarConfig={{
                onClickLeft: () => navigate(-1)
            }}
        >
            <List onLoad={null} finished={true} style={{marginTop: 10}}>
                {data?.length ? data?.map(record => {
                    return <Card style={{marginBottom: 8}} key={record.ratePlanId}>
                        <Card.Header>
                            {
                                record?.regions?.length ? <>
                                    <TextMore data={record.regions}/>
                                </> : null
                            }
                            {volumUnitFormat(record?.volume, record?.type, record?.validity, record?.validityUnit)}
                        </Card.Header>
                        <Cell border={false} title='Generation Time' value={record?.gmtCreated}/>
                        <Cell border={false} title='Specification'
                              value={volumUnitFormat(record?.volume, record?.type, record?.validity, record?.validityUnit)}/>

                        <Cell border={false} title='Quantity' value={record?.period}/>
                        {/* TODO */}
                        {/*<Cell border={false} title='Amount' value={`${record?.currency} ${record?.price}`}/>*/}
                        <Cell border={false} title='Amount' value={`USD ${record?.price}`}/>
                    </Card>
                }) : <Empty description="No Data"/>}
            </List>
        </MainLayout>
    );
}

export default Query;
