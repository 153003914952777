import React, {useEffect} from 'react';
import {
    Divider,
    Cell,
    Card,
    Typography,
    Swiper,
    Grid, Empty, Space,
} from 'react-vant';
import MainLayout from "@/layout/main/MainLayout";
import {GoldCoin, Underway, Clock} from '@react-vant/icons';
import GaugeChart from "@/pages/Home/components/GaugeChart";
import './index.scss'
import {useNavigate, useSearchParams} from 'react-router-dom';

import {useRequest} from "ahooks";
import {getActivationRatePlan} from "@/api/query";
import {volumUnitFormat} from "@/utils/util";

const Query: React.FC = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const cardNo = searchParams.get('iccid')
    const otaCard = searchParams.get('otaCard')
    const {data} = useRequest<any, any>(() => getActivationRatePlan({iccid: cardNo, otaCard}), {
        manual: false,
        refreshDeps: [],
    });
    return (
        <MainLayout
            title="Home"
            navBarConfig={{
                onClickLeft: () => navigate('/query')
            }}
        >
            <Card>
                <Card.Header border>
                    <Typography.Title level={5}>{searchParams.get('iccid')}</Typography.Title>
                </Card.Header>
                <Card.Body>
                    <Swiper autoplay={false} loop={false} style={{
                        "--rv-swipe-indicator-active-background-color": '#5CA1E6',
                    }}>
                        <Swiper.Item style={{paddingBottom: 50}}>
                            {data?.totalDataVolume ?
                                <GaugeChart usageDataVolume={data?.usageDataVolume}
                                            totalDataVolume={data?.totalDataVolume}/> :
                                <Empty description={'No Data'}/>}
                        </Swiper.Item>
                        <Swiper.Item>
                            <Card>
                                <Cell title="SIM Activation Time" value={data?.activateDate}/>
                                <Card.Header style={{fontWeight: 'bold'}}>
                                    Current Plan
                                </Card.Header>
                                <Card.Body style={{'--rv-card-body-padding': 0}}>
                                    <Cell title="Plan Name"
                                          value={volumUnitFormat(data?.baseTotalDataVolume, data?.offerType, data?.validity, data?.validityUnit)}/>
                                    <Cell title="Plan Start Time" value={data?.effectiveDate}/>
                                    <Cell title="Plan Expiration Time" value={data?.expirationDate}/>
                                </Card.Body>
                            </Card>
                        </Swiper.Item>
                    </Swiper>
                </Card.Body>
            </Card>
            <Divider/>

            <Grid columnNum={3} gutter={20}>
                <Grid.Item
                    onClick={() => navigate(`/history?iccid=${cardNo}&otaCard=${otaCard}`)}
                    icon={<Clock color={'#fff'}/>}
                    contentStyle={{background: '#67c23a', color: '#fff'}}
                    text="History"/>
                <Grid.Item
                    onClick={() => navigate(`/plan?iccid=${cardNo}&otaCard=${otaCard}`)}
                    contentStyle={{background: '#5CA1E6', color: '#fff'}}
                    icon={<Underway/>}
                    text="Plan"/>
                <Grid.Item
                    onClick={() => navigate(`/renew?iccid=${cardNo}&otaCard=${otaCard}`)}
                    contentStyle={{background: '#feb810', color: '#fff'}}
                    icon={<GoldCoin/>}
                    text="Renewal"/>
            </Grid>
        </MainLayout>
    );
}

export default Query;
