import axiosInstance from "@/api/axiosInstance";
export type CheckExistParamsType = {
    cardNo: string;
    otaCard: 0 | 1
}

function endPointDis (endPoint, data){
    const isOta = !!+data.otaCard
    delete data.otaCard
    return isOta ? `/3.0/h5/ota/${endPoint}` :`/3.0/h5/${endPoint}`
}
export async function checkExist(data: any) {
    return axiosInstance<CheckExistParamsType>(endPointDis('check/exist', data), {
        method: 'POST',
        params: data,
    });
}
export async function getActivationRatePlan(data: any): Promise<any> {
    return axiosInstance<any>(endPointDis('getActivationRatePlan', data), {
        method: 'POST',
        params: data,
    });
}
export async function getHistoryRatePlan(data: any): Promise<any> {
    return axiosInstance<any>(endPointDis('getHistoryRatePlan', data), {
        method: 'POST',
        params: data,
    });
}

/**
 * 日用量范围查询
 */
export async function getDailyUsageRange(data: any): Promise<any> {
    return axiosInstance<any>(endPointDis('getDailyUsageRange', data), {
        method: 'POST',
        params: data,
    });
}

/**
 * 查询计划套餐
 */
export async function getRatePlan(data: any): Promise<any> {
    return axiosInstance<any>(endPointDis('getRatePlan', data), {
        method: 'POST',
        params: data,
    });
}
/**
 * 单卡可续费套餐信息
 */
export async function renewableRatePlan(data: any): Promise<any> {
    return axiosInstance<any>(endPointDis('renewable/ratePlan', data), {
        method: 'POST',
        params: data,
    });
}
/**
 * 续费记录
 */
export async function ratePlanRecords(data: any): Promise<any> {
    return axiosInstance<any>(endPointDis('ratePlan/records', data), {
        method: 'POST',
        params: data,
    });
}
/**
 * 订单金额计算
 */
export async function payCal(data: any): Promise<any> {
    return axiosInstance<any>(endPointDis('pay/cal', data), {
        method: 'POST',
        params: data,
    });
}
/**
 * 创建订单
 */
export async function payCreate(data: any): Promise<any> {
    return axiosInstance<any>(endPointDis('pay/create', data), {
        method: 'GET',
        params: data,
    });
}


// /*
// * OTA查询当前激活套餐
// * */
// export async function getOtaActivationRatePlan(data: any): Promise<any> {
//     return axiosInstance<any>('/3.0/h5/ota/getActivationRatePlan', {
//         method: 'POST',
//         params: data,
//     });
// }

// /*
// * 查询历史套餐
// * */
// export async function getOtaHistoryRatePlan(data: any): Promise<any> {
//     return axiosInstance<any>('/3.0/h5/ota/getHistoryRatePlan', {
//         method: 'POST',
//         params: data,
//     });
// }
// /*
// * 查询计划套餐
// * */
// export async function getOtaRatePlan(data: any): Promise<any> {
//     return axiosInstance<any>('/3.0/h5/ota/getRatePlan', {
//         method: 'POST',
//         params: data,
//     });
// }
//
// /*
// * 单卡可续费套餐信息
// * */
// export async function otaRenewableRatePlan(data: any): Promise<any> {
//     return axiosInstance<any>('/3.0/h5/ota/renewable/ratePlan', {
//         method: 'POST',
//         params: data,
//     });
// }
//
// /*
// * 订单金额计算
// * */
// export async function otaPayCal(data: any): Promise<any> {
//     return axiosInstance<any>('/3.0/h5/ota/cal', {
//         method: 'POST',
//         params: data,
//     });
// }
