import React, {useRef} from 'react'
import {Popover, Typography, Badge} from 'react-vant'
import type {PopoverInstance} from 'react-vant'

export default ({placeholder, data}: { placeholder?: string; data: String[] }) => {
    const popover = useRef<PopoverInstance>(null)
    const list = [...data]
    const first = placeholder ? placeholder : list.shift()
    return (
        <span style={{marginRight: 4}}>
            {
                data.length > 1 ? <Popover
                    ref={popover}
                    placement='right'
                    teleport={document.body}
                    reference={
                        <Badge content={`+${list.length}`} color={'#3f45ff'}  offset={[0, 0]}>
                            <span>{first}</span>
                        </Badge>
                    }
                >
                    {
                        <div style={{height: 150, overflowY: "auto"}}>
                            {
                                list.map((item, index) => {
                                    return <div style={{padding: '5px 10px'}} key={index}>{item}</div>
                                })
                            }
                        </div>
                    }
                </Popover> : <span>{first}</span>
            }
        </span>
    )
}
