import React, {useState} from 'react';
import {Button, Input, List, Notify, Divider, Cell, Empty, Space, Typography, Sticky} from 'react-vant';
import MainLayout from "@/layout/main/MainLayout";
import styles from "./index.module.scss";
import {Search} from '@react-vant/icons';
import {checkExist} from "@/api/query";
import {useRequest, useLocalStorageState} from "ahooks";
import {useNavigate} from 'react-router-dom';

const Query: React.FC = () => {
    const navigate = useNavigate();
    const [iccid, setIccid] = useState<string>('');
    const [queryRecords, setQueryRecords] = useLocalStorageState<any[]>('query-history', {
        defaultValue: [],
    });

    const {run: check} = useRequest(checkExist, {
        onSuccess(data: any) {
            navigate(`/home?iccid=${data?.cardNo}&otaCard=${data?.otaCard}`);
        },
        manual: true,
    });
    const handleQuery = async () => {
        if (!iccid) {
            Notify.show({
                type: 'danger',
                message: 'Please enter ICCID/CardID',
            });
            return;
        }
        try {
            await check({
                iccid
            })
        } catch (e) {

        }
        setQueryRecords(Array.from(new Set([iccid, ...(queryRecords || [])])));
    };
    return (
        <MainLayout
            title="IoT Card Query"
            navBarConfig={{
                leftArrow: false
            }}
        >
            <div style={{padding: '0 20px'}}>
                <Input
                    className={styles.queryInput}
                    placeholder="Enter ICCID/CardID"
                    value={iccid}
                    prefix={<Search/>}
                    clearable
                    onChange={(value) => setIccid(value?.trim())}
                />
                <Button block type="primary" onClick={handleQuery} size='large'>
                    Query
                </Button>
            </div>

            <Divider>History</Divider>
            {
                queryRecords!!.length > 0 ? <List onLoad={null} finished={true}>
                    {queryRecords?.map((record, index) => (
                        <Cell key={index} onClick={() => setIccid(record)}>{record}</Cell>
                    ))}
                </List> : <Empty description="No Data"/>
            }


            <Space block={true} direction={"vertical"} style={{marginTop: 30}}>
                <Space block={true} divider={true} justify={"center"}>
                    <Typography.Text type="primary" onClick={() => navigate('/docs/terms-of-service')}>Terms Of
                        Service</Typography.Text>
                    <Typography.Text type="primary" onClick={() => navigate('/docs/privacy-policy')}>Privacy
                        Policy</Typography.Text>
                    <Typography.Text type="primary" onClick={() => navigate('/docs/shipping-policy')}>Shipping
                        Policy</Typography.Text>
                </Space>
                <Space block={true} divider={true} justify={'center'}>
                    <Typography.Text type="primary" onClick={() => navigate('/docs/return-and-refund-policy')}>Return
                        & Refund Policy</Typography.Text>
                    <Typography.Text type="primary" onClick={() => navigate('/docs/contact-us')}>Contact
                        Us</Typography.Text>
                </Space>
            </Space>
        </MainLayout>
    );
}

export default Query;
