import React, {useEffect} from 'react';
import MainLayout from "@/layout/main/MainLayout";
import {Typography, Button, Card } from 'react-vant';
import {Checked, Clear, Warning} from '@react-vant/icons';
import {useNavigate, useSearchParams} from "react-router-dom";
import {TypographyType} from "react-vant/es/typography/PropsType";

const Query: React.FC = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const iccid = searchParams.get('iccid')
    const status = searchParams.get('status')
    const channel = searchParams.get('channel')
    const otaCard = searchParams.get('otaCard')

    useEffect(() => {
        if (!iccid) {
            navigate('/query', {replace: true})
        }
    }, [iccid])

    let statusData = {
        title: 'Unknown Error',
        desc: 'An unknown error has occurred. Please try again later.',
        icon: <Warning/>,
        type: 'warning' as TypographyType
    }
    const statusMap = {
        paySuccess: {
            title: 'Payment Successful',
            desc: 'Your payment has been processed successfully.',
            icon: <Checked/>,
            type: 'success'
        },
        payFail: {
            title: 'Payment Failed',
            desc: 'Your payment could not be processed. ',
            icon: <Clear/>,
            type: 'danger'

        }
    }
    statusData = statusMap[status] || statusData
    return (
        <MainLayout
            title={statusData.title}
            navBarConfig={{
                leftArrow: false
            }}
        >
            <Card>
                <Card.Body style={{textAlign: 'center', paddingTop: '70px'}}>
                    <Typography.Text type={statusData.type} style={{fontSize: 70}}>{statusData.icon}</Typography.Text>
                    <Typography.Title level={3}>{statusData.title}</Typography.Title>
                    <Typography.Text color={'#999'}>{statusData.desc}</Typography.Text>
                    {
                        channel === 'whatsapp' ? null : <Button type="primary" size="large" style={{marginTop: '20px'}}
                                                                onClick={() => navigate(`/home?iccid=${iccid}&otaCard=${otaCard}`, {replace: true})}>
                            Back to Home
                        </Button>
                    }
                </Card.Body>
            </Card>
        </MainLayout>
    );
}

export default Query;
