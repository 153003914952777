import React, {useState} from 'react';
import MainLayout from "@/layout/main/MainLayout";
import {useRequest} from "ahooks";
import {getRatePlan} from "@/api/query";
import {useNavigate, useSearchParams} from "react-router-dom";
import PackageList from "@/components/PackageList";
import {Typography, Popup, Button} from 'react-vant'
import {Arrow} from '@react-vant/icons';

const Query: React.FC = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const [visible, setVisible] = useState(false);
    const iccid = searchParams.get('iccid')
    const otaCard = searchParams.get('otaCard')
    const {data: packageRecords} = useRequest(() => getRatePlan({iccid, otaCard}), {
        manual: false,
        refreshDeps: [],
    });
    return (
        <MainLayout
            title="Plan"
            navBarConfig={{
                onClickLeft: () => navigate(-1)
            }}
        >
            <Typography.Link style={{padding: '20px 10px 0', display: 'flex', alignItems: 'center'}}
                             onClick={() => setVisible(true)}>
                Plan Details <Arrow/>
            </Typography.Link>
            <Popup visible={visible} onClose={() => setVisible(false)} position='top'>
                <div style={{padding: '20px'}}>
                    <Typography.Title>
                        Plan Details
                    </Typography.Title>
                    <Typography.Text>
                        The validity period of the plan package is shown on the page, with
                        "valid" indicating the start date and "expired" indicating the end date. During the previous
                        validity period, you can use the data according to the maximum data usage of the package until
                        it is
                        exhausted or expires. This data plan has no speed limit.
                    </Typography.Text>
                </div>
            </Popup>
            <PackageList onLoad={packageRecords} data={packageRecords}/>
        </MainLayout>
    );
}

export default Query;
