import React, {useEffect, useState} from 'react';
import {Button, Card, Cell, Divider, Stepper, Typography, Loading} from 'react-vant';
import {useNavigate, useSearchParams} from "react-router-dom";
import MainLayout from "@/layout/main/MainLayout";
import {useRequest} from "ahooks";
import {payCal, payCreate} from "@/api/query";

const SubscriptionItem: React.FC<any> = ({data}) => {
    const [searchParams] = useSearchParams();

    const [quantity, setQuantity] = useState(1);
    const unitPrice = +searchParams.get('price');
    const autoPay = +searchParams.get('autoPay');
    const channel = searchParams.get('channel');
    const navigate = useNavigate();
    const iccid = searchParams.get('iccid')
    const otaCard = searchParams.get('otaCard')

    const {
        data: totalPrice,
        run
    } = useRequest(() => payCal({iccid, ratePlanId: searchParams.get('ratePlanId'), period: quantity, otaCard}), {
        manual: true,
        debounceWait: 200
    });
    const {
        run: create
    } = useRequest(() => payCreate({
        iccid,
        ratePlanId: searchParams.get('ratePlanId'),
        period: quantity,
        price: totalPrice,
        channel,
        otaCard
    }), {
        manual: true,
        onSuccess(res) {
            window.location.href = res
        }
    });
    useEffect(() => {
        run()
    }, [quantity])
    const submit = () => {
        create()
    }
    useEffect(() => {
        if (autoPay === 1 && totalPrice) {
            create()
        }
    }, [autoPay, totalPrice])
    return (
        autoPay === 1 ? <Loading style={{marginTop: '50%'}} vertical size={100}>
            Preparing Your Payment...
        </Loading> : <MainLayout
            title="Renew"
            navBarConfig={{
                onClickLeft: () => navigate(-1)
            }}
        >
            <Card style={{marginBottom: '10px'}}>
                <Card.Body style={{padding: '30px 0px'}}>
                    <Cell title="ICCID" value={iccid}/>
                    <Cell title="Package Unit" value={searchParams.get('name')}/>
                    <Cell title="Package Regions" >
                        <Typography.Text
                            style={{width: '100%'}}
                            ellipsis={{
                                rows: 2,
                                expandText: 'Show More'
                            }}
                        >
                            {searchParams.get('regions')}
                        </Typography.Text>
                    </Cell>

                    <Cell title="Unit Price" value={`${searchParams.get('currency')} ${unitPrice}`}/>
                    <Cell title="Purchase Period">
                        <Stepper value={quantity} onChange={setQuantity} min={1}
                                 max={+searchParams.get('maxPeriod')}
                                 step={+searchParams.get('rechargeUnit')}/>
                    </Cell>
                    <Cell title="Total Amount" value={`${searchParams.get('currency')} ${totalPrice || '-'}`}/>
                </Card.Body>
                <Card.Footer style={{textAlign: 'center'}}>
                    <Button block type={"primary"} onClick={submit}>Confirm Payment</Button>
                </Card.Footer>
            </Card>
            <Divider/>
            <Card>
                <Card.Body>
                    <Typography.Title level={6}>Purchase Notes</Typography.Title>
                    <Typography.Text>Plan Details：The validity period of the plan is displayed on the page, with
                        "valid"
                        indicating the start date and "expired" indicating the end date. The plan includes all
                        data. The
                        numbers displayed on the page and the limited data plan indicate a specific quantity.
                        During the
                        validity period, you can use the data in the following ways: without restrictions, until
                        it is
                        used up.</Typography.Text>
                    <Typography.Title level={6}>Usage scenario</Typography.Title>
                    <Typography.Text>
                        Only applicable to specific devices, such as cameras and camcorders;
                    </Typography.Text>
                    <Typography.Title level={6}> Regarding refunds
                    </Typography.Title>
                    <Typography.Text>
                        This package will take effect within 30 minutes of purchase and expire after the
                        monthly data resources are used up or the usage period ends. Please contact us if you
                        have any
                        questions.
                    </Typography.Text>

                </Card.Body>
            </Card>

        </MainLayout>
    );
};

export default SubscriptionItem;
